import Image1 from "../../assets/project1.jpg";
import Image2 from "../../assets/project2.jpg";
import Image3 from "../../assets/project3.jpg";
import Image4 from "../../assets/project4.jpg";
import Image5 from "../../assets/project5.jpg";
import Image6 from "../../assets/project6.jpg";
import Image7 from "../../assets/project7.jpg";
import Image8 from "../../assets/project8.jpg";
import Image9 from "../../assets/project9.jpg";

const data = [
  {
    id: 1,
    category: "uiux",
    image: Image1,
    title: "Project Title One (UIUX)",
    desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    demo: "http://egatortutorials.com",
    github: "https://github.com/egattor",
  },
  {
    id: 2,
    category: "frontend",
    image: Image2,
    title: "Natours | Exciting Tours for Adventurous People",
    desc: "Natours is a web project that offers exciting tours for adventurous people. It provides users with detailed information about various tours, allows them to book tours, and shares stories from people who have experienced the tours. This project demonstrates the use of advanced CSS and Sass for creating a beautiful and responsive web design.",
    demo: "https://natours-frontend-rose.vercel.app/",
    github: "https://github.com/Kamrancodex/Natours-frontend",
  },
  {
    id: 3,
    category: "frontend",
    image: Image3,
    title: "Project Title Three (Frontend)",
    desc: "Nexter is a web project that showcases luxurious homes from around the world. It provides users with detailed information about various properties, top realtors, and customer stories. This project demonstrates the use of advanced CSS and Sass for creating a visually appealing and responsive web design.",
    demo: "https://nexter-frontend.vercel.app/",
    github: "https://github.com/Kamrancodex/Nexter-frontend",
  },
  {
    id: 4,
    category: "uiux",
    image: Image4,
    title: "Project Title Four (UIUX)",
    desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    demo: "http://egatortutorials.com",
    github: "https://github.com/egattor",
  },
  {
    id: 5,
    category: "uiux",
    image: Image5,
    title: "Project Title Five (UIUX)",
    desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! ",
    demo: "http://egatortutorials.com",
    github: "https://github.com/egattor",
  },
  {
    id: 6,
    category: "frontend",
    image: Image6,
    title: "Swipe",
    desc: "Swipe is a web project that showcases a seamless platform for managing a business's entire financial transaction infrastructure online. This project demonstrates the use of advanced HTML, CSS, and JavaScript to create a visually appealing and responsive web design.",
    demo: "https://kamrancodex.github.io/swipe-frontend/",
    github: "https://github.com/Kamrancodex/swipe-frontend",
  },
  {
    id: 7,
    category: "frontend",
    image: Image7,
    title: "Portfolio Website(Frontend)",
    desc: "Welcome to my portfolio website! This is a personal project showcasing my skills, projects, and experience as a web developer. Below you'll find details about the project, how to run it locally, and the technologies used.",
    demo: "https://kamrancodex.github.io/portfolio-frontend/",
    github: "https://github.com/Kamrancodex/portfolio-frontend",
  },
  {
    id: 8,
    category: "backend",
    image: Image8,
    title: "Project Title Eight (Backend)",
    desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    demo: "http://egatortutorials.com",
    github: "https://github.com/egattor",
  },
  {
    id: 9,
    category: "frontend",
    image: Image9,
    title: "Corso Training (Frontend)",
    desc: "Welcome to Corso Training, a platform where you can explore and enhance your skills through our training and seminar videos. Whether you're a beginner or an experienced professional, our carefully curated content will empower you to expand your knowledge and expertise!",
    demo: "https://corso-frontend.vercel.app/",
    github: "https://github.com/Kamrancodex/corso-frontend",
  },
];

export default data;
